import { IPackageProperties, IAccessoryProperties } from '~/types/fightcamp';
import { FormattedProduct } from '@fc/app-common/src/services/Shopify/types';
import { ISelectorFCAccessory, ISelectorFCPackage } from '~/selectors/types';

function getProductById(productsCollection: FormattedProduct[], id: string): FormattedProduct {
  const foundProduct = productsCollection.find(p => p.id === id);

  if (!foundProduct) {
    return {
      id: '',
      title: '',
      description: '',
      image: '',
      productType: '',
      tags: [],
      variants: [],
      metadata: [],
    };
  }

  return foundProduct;
}

// transforms a package product's shopify and storyblok data into one data object
export function transformPackageProduct({ cmsContent, eCommContent }: { cmsContent: ISelectorFCPackage, eCommContent: FormattedProduct[] }): IPackageProperties {
  const eCommPackage = getProductById(eCommContent, cmsContent.eCommId);

  return {
    productName: cmsContent?.title ?? '',
    id: eCommPackage?.id ?? '',
    variants: eCommPackage?.variants?.map((variant) => ({
      id: variant?.id,
      uid: variant?.uid,
      sku: variant?.sku,
      name: variant?.name,
    })) || [],
    pathId: `/shop/products/${cmsContent?.pathId}` ?? '', // need this to create url but may be a better way to do this
    productType: eCommPackage?.productType ?? '',
    tags: eCommPackage?.tags ?? [],
    price: eCommPackage?.variants[0]?.price ?? 0,
    oldPrice: eCommPackage?.variants[0]?.compareAtPrice ?? null,
    description: cmsContent?.description ?? '',
    shortDescription: cmsContent?.shortDescription ?? '',
    isSoldOut: cmsContent?.isSoldOut ?? false,
    includesBag: cmsContent?.includesBag ?? false,
    primaryImg: {
      src: cmsContent?.primaryImg?.src ?? '',
      alt: cmsContent?.primaryImg?.alt ?? '',
    },
    assets: cmsContent?.assets ?? [],
    includedProducts: cmsContent?.includes?.map((item) => {
      const eCommAccessory = getProductById(eCommContent, item.eCommId) || {};
      return transformAccessoryProduct({ cmsContent: item, eCommContent: eCommAccessory });
    }) || [],
    upsells:
      {
        gloveUpsells: cmsContent?.gloveUpsells?.map((item) => {
          const eCommAccessory = getProductById(eCommContent, item.eCommId);
          return transformAccessoryProduct({ cmsContent: item, eCommContent: eCommAccessory });
        }) || [],
        upsells: cmsContent?.upsells?.map((item) => {
          const eCommAccessory = getProductById(eCommContent, item.eCommId);
          return transformAccessoryProduct({ cmsContent: item, eCommContent: eCommAccessory });
        }),
      },
    warranties: cmsContent?.warranties?.map((item) => {
      const eCommAccessory = getProductById(eCommContent, item.eCommId);
      return transformAccessoryProduct({ cmsContent: item, eCommContent: eCommAccessory });
    }) || [],
  };
}

function transformAccessoryProduct({ cmsContent, eCommContent }: {cmsContent: ISelectorFCAccessory, eCommContent: FormattedProduct}): IAccessoryProperties {
  return {
    productName: cmsContent?.title ?? '',
    id: eCommContent?.id ?? '',
    // refactor below to helper function since package is using it too
    variants: eCommContent?.variants.map((variant) => ({ // assume price is the same so dont grab it here
      id: variant.id,
      uid: variant.uid,
      sku: variant.sku,
      name: variant.name, // did not include title since it seems to be the same as name
    })),
    pathId: cmsContent?.pathId ?? '', // need this to create url but may be a better way to do this
    productType: eCommContent?.productType ?? '',
    tags: eCommContent?.tags ?? [],
    price: eCommContent?.variants?.[0]?.price ?? 0,
    oldPrice: eCommContent?.variants?.[0]?.compareAtPrice ?? null,
    description: cmsContent?.description ?? '',
    shortDescription: cmsContent?.shortDescription ?? '',
    isSoldOut: cmsContent?.isSoldOut ?? false,
    primaryImg: {
      src: cmsContent?.primaryImg?.src ?? '',
      alt: cmsContent?.primaryImg?.alt ?? '',
    },
    assets: cmsContent?.assets ?? [],
    lifestyleImage: cmsContent?.lifestyleImage ?? [], // only accessories has this for now
    includesModalCopy: cmsContent?.includesModalCopy ?? null, // return nothing so it i snot a value
  };
}
