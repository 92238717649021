var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageSection',_vm._b({},'PageSection',_vm.atfv3,false)),_vm._v(" "),_c('div',{staticClass:"fc-bg-blue fc-text-white fc-text-center fc-padding-bottom-14"},[_c('div',{staticClass:"fc-container-lg fc-padding-top-14 fc-padding-x-2 md:fc-padding-x-2"},[_c('h2',{staticClass:"fc-title"},[_vm._v("\n        Build Strength For Life\n      ")]),_vm._v(" "),_c('p',{staticClass:"build-strength__copy fc-text-base fc-padding-top-6 md:fc-padding-bottom-10"},[_vm._v("\n        FightCamp is more than just a workout—it’s your chance to show up a little better every day. Track your punches and progress in real time. Choose from an ever-growing library of on-demand workouts. Follow along with trainers as they motivate you to push harder.\n      ")]),_vm._v(" "),_c('div',{staticClass:"fc-grid fc-magin-x-2"},[_c('div',{staticClass:"build-strength__video sm:fc-col-span-5 md:fc-col-span-4 fc-flex fc-column"},[_c('p',{staticClass:"fc-heading-2 fc-block md:fc-hidden fc-padding-bottom-4 fc-padding-top-10"},[_vm._v("\n            Fitness That Keeps You Hooked\n          ")]),_vm._v(" "),_c('MediaBackground',{attrs:{"background-color":"white","first-frame-image-alt":"","first-frame-image-url":"https://a.storyblok.com/f/152150/1080x1080/e96e3e1c24/1x1-commit-to-progress.jpg","is-full-width":true,"media-position":"center","mobile-video-playback":"auto","mp4-mobile-video":"","mp4-video":"https://cdn.joinfightcamp.com/videos/HOME+PAGE+FITNESS+THAT+KEEPS+YOU+HOOKED+(small).mp4","tablet-up-video-playback":"auto","video-component":{
              _uid: 'e4f8bad7-5130-4624-aef1-d009fa6b1370b',
              codeId: '',
              rounded: true,
            }}}),_vm._v(" "),_c('p',{staticClass:"build-strength__tablet-up-text--first fc-heading-2 fc-hidden md:fc-block fc-padding-y-5"},[_vm._v("\n            Fitness That Keeps You Hooked\n          ")]),_vm._v(" "),_c('p',{staticClass:"build-strength__copy fc-text-base fc-padding-top-4 md:fc-padding-0"},[_vm._v("\n            Discover a workout that challenges and motivates you every step of the way.\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"build-strength__video sm:fc-col-span-5 md:fc-col-span-4 fc-flex fc-column"},[_c('p',{staticClass:"fc-heading-2 fc-block md:fc-hidden fc-padding-bottom-4 fc-padding-top-10"},[_vm._v("\n            Train Beyond The Body\n          ")]),_vm._v(" "),_c('MediaBackground',{attrs:{"background-color":"white","first-frame-image-alt":"","first-frame-image-url":"https://a.storyblok.com/f/152150/1080x1080/e43b66b097/1x1-work-your-body-and-brain.jpg","is-full-width":true,"media-position":"center","mobile-video-playback":"auto","mp4-mobile-video":"","mp4-video":"https://cdn.joinfightcamp.com/videos/HOME+PAGE+TRAIN+BEYOND+THE+BODY+(small).mp4","tablet-up-video-playback":"auto","video-component":{
              _uid: 'e4f8bad7-5130-4624-aef1-d009fa6b1370c',
              codeId: '',
              rounded: true,
            }}}),_vm._v(" "),_c('p',{staticClass:"build-strength__tablet-up-text fc-heading-2 fc-hidden md:fc-block fc-padding-y-5"},[_vm._v("\n            Train Beyond The Body\n          ")]),_vm._v(" "),_c('p',{staticClass:"build-strength__copy fc-text-base fc-padding-top-4 md:fc-padding-0"},[_vm._v("\n            Achieve more than just physical gains—unlock focus, stress relief, and mental clarity.\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"build-strength__video sm:fc-col-span-5 md:fc-col-span-4 fc-flex fc-column"},[_c('p',{staticClass:"fc-heading-2 fc-block md:fc-hidden fc-padding-bottom-4 fc-padding-top-10"},[_vm._v("\n            Push Your Limits\n          ")]),_vm._v(" "),_c('MediaBackground',{attrs:{"background-color":"white","first-frame-image-alt":"","first-frame-image-url":"https://a.storyblok.com/f/152150/1080x1080/2acf9354f4/1x1-full-body-results.jpg","is-full-width":true,"media-position":"center","mobile-video-playback":"auto","mp4-mobile-video":"","mp4-video":"https://cdn.joinfightcamp.com/videos/HOME+PAGE+PUSH+YOUR+LIMITS+(small).mp4","tablet-up-video-playback":"auto","video-component":{
              _uid: 'e4f8bad7-5130-4624-aef1-d009fa6b1370d',
              codeId: '',
              rounded: true,
            }}}),_vm._v(" "),_c('p',{staticClass:"build-strength__tablet-up-text fc-heading-2 fc-hidden md:fc-block fc-padding-y-5"},[_vm._v("\n            Push Your Limits\n          ")]),_vm._v(" "),_c('p',{staticClass:"build-strength__copy fc-text-base fc-padding-top-4 md:fc-padding-0"},[_vm._v("\n            Proven boxing workouts to build strength and endurance.\n          ")])],1)])])]),_vm._v(" "),_c('div',{staticClass:"fc-container-lg fc-padding-y-12 fc-padding-x-2"},[_c('h2',{staticClass:"fc-title fc-text-center fc-margin-bottom-10"},[_vm._v("\n      Let’s Break Down FightCamp\n    ")]),_vm._v(" "),_c('p',{staticClass:"fc-text-center fc-text-base fc-padding-top-6 md:fc-padding-bottom-10"},[_vm._v("\n      With FightCamp you get everything you need to experience the best workout of your life:\n    ")]),_vm._v(" "),_vm._l((_vm.breakDown),function(data,index){return _c('div',{key:data.title,class:{'md:fc-padding-bottom-17': index !== 2}},[_c('h2',{staticClass:"fc-block md:fc-hidden fc-heading-2 fc-text-center fc-padding-bottom-5 fc-padding-top-12"},[_vm._v("\n        "+_vm._s(data.title)+"\n      ")]),_vm._v(" "),_c('SplitView',{attrs:{"resize-equally":true,"is-reverse-order":data.isReverseOrder,"ignore-media-query-for":"tablet"},scopedSlots:_vm._u([{key:"replaceResponsiveImage",fn:function(){return [_c('MediaBackground',{attrs:{"background-color":"white","first-frame-image-alt":"","first-frame-image-url":data.firstFrameImageUrl,"is-full-width":true,"media-position":"center","mobile-video-playback":"auto","mp4-mobile-video":"","mp4-video":data.mp4VideoUrl,"tablet-up-video-playback":"auto","video-component":{
              rounded: true,
            }}})]},proxy:true}],null,true)},[_vm._v(" "),_c('div',{staticClass:" fc-flex fc-column",class:{'md:fc-padding-right-15': data.isReverseOrder, 'md:fc-padding-left-15': !data.isReverseOrder }},[_c('h2',{staticClass:"fc-hidden md:fc-block fc-heading-2"},[_vm._v("\n            "+_vm._s(data.title)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"fc-text fc-padding-top-6"},[_vm._v("\n            "+_vm._s(data.copy)+"\n          ")]),_vm._v(" "),_c('FCLinkButton',{directives:[{name:"track-click",rawName:"v-track-click",value:(((data.ctaLink) + "_Shop")),expression:"`${data.ctaLink}_Shop`"}],staticClass:"fc-padding-top-4",attrs:{"button-theme":"button-secondary","cta-text":data.buttonCta,"cta-link":data.buttonLink,"button-alignment":"left","element-name":"NuxtLink"}})],1)])],1)})],2),_vm._v(" "),_c('Articles',{attrs:{"quotes":_vm.quotes,"logos":_vm.logos}}),_vm._v(" "),_c('PageSection',_vm._b({},'PageSection',_vm.banner1,false)),_vm._v(" "),_c('div',{staticClass:"boxing-better fc-bg-blue fc-text-white"},[_c('h2',{staticClass:"fc-title fc-text-center fc-padding-bottom-6"},[_vm._v("\n      Boxing outperforms every other workout, science says so\n    ")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('LinkButton',{attrs:{"cta-text":"Learn More","cta-link":"/why-choose-fightcamp","button-theme":"button-secondary","button-text-size":"default","button-alignment":"center"}})],1),_vm._v(" "),(_vm.reviews.length)?_c('ReviewsGrid',{attrs:{"reviews":_vm.reviews,"rating":"4.9","gallery-title":"Customer Photos","rating-title":"21,000+ Apple Store Reviews"}}):_vm._e(),_vm._v(" "),_c('TestimonialVideos'),_vm._v(" "),_c('PageSection',_vm._b({staticClass:"fc-margin-top-10"},'PageSection',_vm.banner2,false))],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"fc-text-center fc-text-base fc-padding-bottom-8"},[_vm._v("\n      Why settle for less when you can have the ultimate workout? Boxing engages every muscle, blending cardio, strength, and endurance for unmatched results. "),_c('br'),_vm._v(" Build lean muscle, burn calories, sharpen your focus, and relieve stress—all in one session. "),_c('br'),_vm._v(" Discover the science behind why boxing outperforms other workouts.\n    ")])}]

export { render, staticRenderFns }