
import { metaData } from '~/configuration/metaData';
import { CMSService } from '~/services/storyBlok/cmsService';
import NavigationCardContainer from '~/components/v2/NavigationCardContainer.vue';
import HomeVideoClip from '~/components/v2/home/HomeVideoClip.vue';
import TestimonialVideos from '~/components/v2/home/TestimonialVideos.vue';
import { transformPackageProduct } from '~/utils/transformers';
import { selectFCBaseProduct } from '~/selectors/selectors';
import ConsoleATF from '~/components/storyblokUnique/ConsoleATF.vue';
import HomeBrowsePackagesVariant from '~/components/v2/home/HomeBrowsePackagesVariant.vue';
import WebsiteV3Home from '~/components/v3/home/WebsiteV3Home.vue';

export default {
  name: 'Home',
  head: {
    ...metaData.home,
  },
  components: {
    ConsoleATF,
    NavigationCardContainer,
    HomeBrowsePackagesVariant,
    HomeVideoClip,
    TestimonialVideos,
    WebsiteV3Home,
  },
  data() {
    return {
      transformedPackageProducts: [],
    };
  },
  computed: {
    isWebsiteV3Variant() {
      return this.$store.getters.exp_031_website_v3;
    },
  },
  provide() {
    return {
      fcPackagesData: this.transformedPackageProducts,
    };
  },
  // TODO very similar pattern to shop chance to refactor?
  async asyncData({ $storyapi, error, store }) {
    const storyBlok = new CMSService({ cmsService: $storyapi });
    try {
      const resp = await storyBlok.get('v2/home', { resolve_relations: 'ProductData.ecommerceProducts' });
      const packageProductsResp = resp?.data?.story?.content?.body[0]?.ecommerceProducts;

      const transformedPackageProducts = packageProductsResp.map(product => {
        const cmsContent = selectFCBaseProduct(product);
        const eCommContent = store.getters.productsCollection;

        return transformPackageProduct({ cmsContent, eCommContent });
      });

      return { transformedPackageProducts };
    } catch (err) {
      error({ message: err?.message });
    }

    return [];
  },
};
