
























































import {
  defineComponent,
  computed,
  PropType,
} from '@vue/composition-api';
import { IPackageProperties } from '~/types/fightcamp';
import { FCCard, FiveStars } from '@fc/angie-ui';
import FCImage from '~/components/FCImage.vue';

export default defineComponent({
  name: 'ProductPackageCards',
  components: {
    FCCard,
    FiveStars,
    FCImage,
  },
  props: {
    fcPackages: {
      type: Array as PropType<IPackageProperties[]>,
      required: true,
    },
  },
  setup(props) {
    function getImage(pkg: IPackageProperties) {
      return {
        src: pkg.assets[1].src,
        alt: pkg.assets[1].alt,
      };
    }

    // @ts-ignore
    const { $analytics } = useNuxtApp();
    const productClicked = (pkg: IPackageProperties) => { $analytics.productClicked(pkg); };
    const selectedPkgs = computed(() => props.fcPackages.filter((pkg) => pkg.pathId === '/shop/products/fightcamp-starter'));

    return {
      productClicked,
      getImage,
      selectedPkgs,
    };
  },
});
