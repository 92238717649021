
import { defineComponent } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';

export default defineComponent({
  name: 'NavigationCard',
  components: {
    FCImage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
