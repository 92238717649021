














































import {
  defineComponent, ref, onMounted, Ref, computed,
  onBeforeUnmount,
} from '@vue/composition-api';
import { FiveStars } from '@fc/angie-ui';
import ReviewPercentageBar from '~/components/ReviewPercentageBar.vue';
import { isTabletUpEffect } from '~/effects/matchMedia';

const CONTAINER_BOUNDARY = 506;

export default defineComponent({
  name: 'ReviewOverview',
  inheritAttrs: false,
  components: {
    FiveStars,
    ReviewPercentageBar,
  },
  props: {
    rating: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isValid = computed(() => (props.rating !== '' && props.title !== ''));

    const reviewContainer = ref() as Ref<HTMLElement>;
    const isAtTop = ref(false);
    const isAtBottom = ref(false);
    const isTabletUp = isTabletUpEffect();
    const reviewStyle = ref({}) as Ref<{ maxWidth: string }>;

    const options = {
      root: reviewContainer.value,
      rootMargin: '0px',
      threshold: 0.1,
    };
    let observer: IntersectionObserver;

    // TODO refactor scrollReviews nested if/else statements
    function scrollReviews() {
      const elementRect = reviewContainer.value.getBoundingClientRect();
      const navigationBarHeight = document.getElementById('mainNavigation')?.clientHeight || 0;
      const reviewHeightFromTop = navigationBarHeight + 20;

      reviewStyle.value = { maxWidth: `${elementRect?.width}px` };

      if (isTabletUp.value) {
        if (elementRect.top <= reviewHeightFromTop) {
          isAtTop.value = true;
        } else {
          isAtTop.value = false;
        }

        if (elementRect.bottom <= CONTAINER_BOUNDARY) {
          isAtTop.value = false;
          isAtBottom.value = true;
        } else if (elementRect.top <= reviewHeightFromTop) {
          isAtTop.value = true;
          isAtBottom.value = false;
        }
      }
    }

    onMounted(() => {
      if (process.client) {
        observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              window.addEventListener('scroll', scrollReviews);
            } else {
              window.removeEventListener('scroll', scrollReviews);
            }
          });
        }, options);

        if (reviewContainer.value) {
          observer.observe(reviewContainer.value);
        }
      }
    });

    onBeforeUnmount(() => {
      if (process.client) {
        if (reviewContainer.value) {
          observer.unobserve(reviewContainer.value);
        }

        observer.disconnect();
      }
    });

    return {
      reviewContainer,
      isValid,
      isAtTop,
      isAtBottom,
      reviewStyle,
    };
  },
});
