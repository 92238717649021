
import { defineComponent, ref, onMounted } from '@vue/composition-api';

// TODO need to clean up Video component once design is confirmed and first frame
const mediaBackgroundData = {
  firstFrameImageUrl: 'https://a.storyblok.com/f/152150/1920x1080/0d6842a9d0/fc-console-home-first-frame-001.png',
  mp4MobileVideo: 'https://cdn.joinfightcamp.com/videos/fc-console-home-video-001.mp4',
  mp4Video: 'https://cdn.joinfightcamp.com/videos/fc-console-home-video-001.mp4',
  webmMobileVideo: 'https://cdn.joinfightcamp.com/videos/fc-console-home-video-001.webm',
  webmVideo: 'https://cdn.joinfightcamp.com/videosfc-console-home-video-001.webm',
  videoComponent: {
    _uid: 'e473f740-26f4-454d-afe1-108bb14879cd',
    codeId: '',
    component: 'Video',
    isOverlay: false,
    playsAudio: false,
    hasControls: false,
    hasPlayIcon: false,
    hasPauseIcon: true,
    mp4VideoUrl: 'https://cdn.joinfightcamp.com/videos/fc-console-home-video-001.mp4',
    webmVideoUrl: 'https://cdn.joinfightcamp.com/videosfc-console-home-video-001.webm',
    firstFrameImage: {
      id: 16590149,
      alt: '',
      name: '',
      focus: '',
      title: '',
      source: '',
      filename: 'https://a.storyblok.com/f/152150/1920x1080/0d6842a9d0/fc-console-home-first-frame-001.png',
      copyright: '',
      fieldtype: 'asset',
      meta_data: {},
      is_private: false,
      is_external_url: false,
    },
    mp4MobileVideoUrl: '',
    webmMobileVideoUrl: '',
    mobileVideoPlayback: '',
    tabletUpVideoPlayback: '',
  },
};

export default defineComponent({
  name: 'HomeVideoClip',
  setup() {
    const homeVideoClipEl = ref<HTMLElement | null>(null);

    onMounted(() => {
      // take in account the nav bar height to get ATF height
      const navBar = document.getElementById('mainNavigation');
      if (navBar && homeVideoClipEl.value) {
        homeVideoClipEl.value.style.height = `calc(100vh - ${navBar.offsetHeight}px)`;
      }
    });

    return {
      homeVideoClipEl,
      mediaBackgroundData,
    };
  },
});

