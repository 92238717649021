
import { LinkButton } from '@fc/angie-ui';
import { CMSService } from '~/services/storyBlok/cmsService';
import PageSection from '~/components/storyblok/PageSection/PageSection.vue';
import TestimonialVideos from '~/components/v2/home/TestimonialVideos.vue';
import ReviewsGrid from '~/components/storyblok/ReviewsGrid/ReviewsGrid.vue';
import MediaBackground from '~/components/storyblok/MediaBackground/MediaBackground.vue';
import SplitView from '~/components/SplitView.vue';
import Articles from './Articles.vue';

const breakDownData = [
  {
    mediaBackground: {},
    title: 'Get A Brag-Worthy Bag',
    copy: 'The only bag that stays in place—no matter how hard you hit. Engineered for stability and durability, the FightCamp bag lets you go all-out without chasing it around your home gym.',
    buttonCta: 'Check Out The Equipment',
    buttonLink: 'shop#explore-the-gym',
    mp4VideoUrl: 'https://cdn.joinfightcamp.com/videos/HOME+PAGE+BRAG+WORTHY+BAG+(SMALL).mp4',
    webmVideoUrl: '',
    firstFrameImageUrl: 'https://a.storyblok.com/f/152150/1080x1080/1ca64a1c68/1x1-brag-worthy-bag.jpg',
  },
  {
    mediaBackground: {},
    title: 'Track Every Move, Get Fit Faster',
    copy: 'Real-time tracking and punch goals push you to unlock new achievements. Compete in challenges and measure your growth against the community.',
    buttonCta: 'See Our Technology',
    buttonLink: 'shop#console-trackers',
    isReverseOrder: true,
    mp4VideoUrl: 'https://cdn.joinfightcamp.com/videos/HOME+PAGE+TRACK+EVERY+MOVE+(small).mp4',
    webmVideoUrl: 'https://cdn.joinfightcamp.com/videos/TRACK+EVERY+MOVE.webm',
    firstFrameImageUrl: 'https://a.storyblok.com/f/152150/1080x1080/c0e76c8e33/1x1-track-every-move.jpg',
  },
  {
    mediaBackground: {},
    title: 'Stronger Body & Sharper Mind with Every Workout',
    copy: 'FightCamp’s boxing & kickboxing workouts strengthen both your body and mind. Offering more variety, and more dynamic movements, than cycling or rowing. Each session builds mental focus and physical endurance.',
    buttonCta: 'Explore Our Workouts',
    buttonLink: 'shop#workout-library',
    mp4VideoUrl: 'https://cdn.joinfightcamp.com/videos/HOME+PAGE+AN+ALL+IN+ONE+LIBRARY(small).mp4',
    webmVideoUrl: '',
    firstFrameImageUrl: 'https://a.storyblok.com/f/152150/1080x1080/23fbac4002/1x1-stronger-body-sharper-mind.jpg',
  },
];

const banner1 = {
  _uid: '8e8a4df5-9f8f-4c68-b437-7450c3f27a85',
  codeId: 'WhyChooseFC_ShopBanner',
  height: 'auto',
  scroll: '',
  component: 'PageSection',
  container: [
    {
      _uid: 'f48e0971-7569-4f23-8caa-615d75e4b7a4',
      codeId: '',
      component: 'Banner',
      backgroundColor: '',
      contentPosition: 'center',
      contentContainer: [
        {
          _uid: '93f2d5f0-bf4b-4626-9725-e2e92f408b52',
          codeId: '',
          component: 'MediaBackground',
          brightness: '1',
          isFullWidth: false,
          mediaPosition: '',
          mediaContainer: [
            {
              _uid: 'c0932db2-94f2-46da-a553-02845ff6cfa3',
              Image: {
                id: 16413978,
                alt: '',
                name: '',
                focus: '',
                title: '',
                source: '',
                filename: 'https://a.storyblok.com/f/152150/5788x2576/97c1794ae8/image-4.jpg',
                copyright: '',
                fieldtype: 'asset',
                meta_data: {},
                is_private: false,
                is_external_url: false,
              },
              codeId: '',
              component: 'PictureResponsive',
              imageType: '',
              _editable: '<!--#storyblok#{"name": "PictureResponsive", "space": "152150", "uid": "c0932db2-94f2-46da-a553-02845ff6cfa3", "id": "196275687"}-->',
            },
          ],
          backgroundColor: '',
          _editable: '<!--#storyblok#{"name": "MediaBackground", "space": "152150", "uid": "93f2d5f0-bf4b-4626-9725-e2e92f408b52", "id": "196275687"}-->',
        },
        {
          _uid: 'a9a42e5b-6e27-435a-95af-a0364bcd3456',
          text: 'Shop FightCamp Now',
          codeId: '',
          isBold: false,
          component: 'ShortText',
          textColor: 'eggshell',
          textTheme: 'heading-2',
          paddingTop: 'fc-padding-top-0',
          elementName: 'h2',
          isUpperCase: false,
          paddingLeft: 'fc-padding-left-0',
          paddingRight: 'fc-padding-right-0',
          paddingBottom: 'fc-padding-bottom-8',
          textAlignment: 'fc-text-center',
          _editable: '<!--#storyblok#{"name": "ShortText", "space": "152150", "uid": "a9a42e5b-6e27-435a-95af-a0364bcd3456", "id": "196275687"}-->',
        },
        {
          _uid: '53236532-1ed8-453c-a9e1-545394ef74e5',
          codeId: '',
          ctaLink: {
            id: '12371015-d3c2-4356-94ab-d274095163ca',
            url: '',
            linktype: 'story',
            fieldtype: 'multilink',
            cached_url: 'shop',
          },
          ctaText: 'Shop Now',
          component: 'LinkButton',
          fullWidth: false,
          buttonTheme: 'button-primary',
          buttonTextSize: 'default',
          buttonAlignment: '',
          _editable: '<!--#storyblok#{"name": "LinkButton", "space": "152150", "uid": "53236532-1ed8-453c-a9e1-545394ef74e5", "id": "196275687"}-->',
        },
      ],
      contentContainerWidth: '',
      _editable: '<!--#storyblok#{"name": "Banner", "space": "152150", "uid": "f48e0971-7569-4f23-8caa-615d75e4b7a4", "id": "196275687"}-->',
    },
  ],
  elementId: '',
  minHeight: '',
  inContainer: false,
  backgroundColor: '',
  _editable: '<!--#storyblok#{"name": "PageSection", "space": "152150", "uid": "8e8a4df5-9f8f-4c68-b437-7450c3f27a85", "id": "196275687"}-->',
};

const banner2 = {
  _uid: '8e8a4df5-9f8f-4c68-b437-7450c3f27a85',
  codeId: 'WhyChooseFC_ShopBanner',
  height: 'auto',
  scroll: '',
  component: 'PageSection',
  container: [
    {
      _uid: 'f48e0971-7569-4f23-8caa-615d75e4b7a4',
      codeId: '',
      component: 'Banner',
      backgroundColor: '',
      contentPosition: 'center',
      contentContainer: [
        {
          _uid: '93f2d5f0-bf4b-4626-9725-e2e92f408b52',
          codeId: '',
          component: 'MediaBackground',
          brightness: '1',
          isFullWidth: false,
          mediaPosition: '',
          mediaContainer: [
            {
              _uid: 'c0932db2-94f2-46da-a553-02845ff6cfa3',
              Image: {
                id: 16413978,
                alt: '',
                name: '',
                focus: '',
                title: '',
                source: '',
                filename: 'https://a.storyblok.com/f/152150/5760x2576/bcf4e21ca0/image-5.jpg',
                copyright: '',
                fieldtype: 'asset',
                meta_data: {},
                is_private: false,
                is_external_url: false,
              },
              codeId: '',
              component: 'PictureResponsive',
              imageType: '',
              _editable: '<!--#storyblok#{"name": "PictureResponsive", "space": "152150", "uid": "c0932db2-94f2-46da-a553-02845ff6cfa3", "id": "196275687"}-->',
            },
          ],
          backgroundColor: '',
          _editable: '<!--#storyblok#{"name": "MediaBackground", "space": "152150", "uid": "93f2d5f0-bf4b-4626-9725-e2e92f408b52", "id": "196275687"}-->',
        },
        {
          _uid: 'a9a42e5b-6e27-435a-95af-a0364bcd3456',
          text: 'Shop FightCamp Now',
          codeId: '',
          isBold: false,
          component: 'ShortText',
          textColor: 'eggshell',
          textTheme: 'heading-2',
          paddingTop: 'fc-padding-top-0',
          elementName: 'h2',
          isUpperCase: false,
          paddingLeft: 'fc-padding-left-0',
          paddingRight: 'fc-padding-right-0',
          paddingBottom: 'fc-padding-bottom-8',
          textAlignment: 'fc-text-center',
          _editable: '<!--#storyblok#{"name": "ShortText", "space": "152150", "uid": "a9a42e5b-6e27-435a-95af-a0364bcd3456", "id": "196275687"}-->',
        },
        {
          _uid: '53236532-1ed8-453c-a9e1-545394ef74e5',
          codeId: '',
          ctaLink: {
            id: '12371015-d3c2-4356-94ab-d274095163ca',
            url: '',
            linktype: 'story',
            fieldtype: 'multilink',
            cached_url: 'shop',
          },
          ctaText: 'Shop Now',
          component: 'LinkButton',
          fullWidth: false,
          buttonTheme: 'button-primary',
          buttonTextSize: 'default',
          buttonAlignment: '',
          _editable: '<!--#storyblok#{"name": "LinkButton", "space": "152150", "uid": "53236532-1ed8-453c-a9e1-545394ef74e5", "id": "196275687"}-->',
        },
      ],
      contentContainerWidth: '',
      _editable: '<!--#storyblok#{"name": "Banner", "space": "152150", "uid": "f48e0971-7569-4f23-8caa-615d75e4b7a4", "id": "196275687"}-->',
    },
  ],
  elementId: '',
  minHeight: '',
  inContainer: false,
  backgroundColor: '',
  _editable: '<!--#storyblok#{"name": "PageSection", "space": "152150", "uid": "8e8a4df5-9f8f-4c68-b437-7450c3f27a85", "id": "196275687"}-->',
};

const atfv3 = {
  _uid: 'e95b0403-0dc9-4ee2-ae74-5176c50fe28d',
  codeId: 'WhyChooseFC_ATF',
  height: '94vh',
  scroll: '',
  component: 'PageSection',
  container: [
    {
      _uid: '97231b6d-307a-4126-9d8a-43fb08bc1a3c',
      codeId: '',
      component: 'AboveTheFoldView',
      contentContainer: [
        {
          _uid: '0cb6afdc-ba94-4ae8-b6e6-41ad6da436cc',
          codeId: 'Home_ATFHero',
          component: 'Hero',
          containerSize: '',
          contentContainer: [
            {
              _uid: 'f367f93f-2920-4841-bb9e-a94690f10f6c',
              text: 'stay motivated. get stronger.',
              codeId: '',
              isBold: false,
              component: 'ShortText',
              textColor: 'eggshell',
              textTheme: 'heading-1',
              paddingTop: 'fc-padding-top-0',
              elementName: '',
              isUpperCase: false,
              paddingLeft: 'fc-padding-left-0',
              paddingRight: 'fc-padding-right-0',
              paddingBottom: 'fc-padding-bottom-0',
              textAlignment: 'fc-text-left',
              _editable: '\u003C!--#storyblok#{"name": "ShortText", "space": "152150", "uid": "f367f93f-2920-4841-bb9e-a94690f10f6c", "id": "196275687"}--\u003E',
            },
            {
              _uid: '49c89cd9-c855-400c-9db7-880f1d582a8a',
              text: 'AT-HOME BOXING, KICKBOXING, STRENGTH TRAINING & MORE',
              codeId: '',
              isBold: false,
              component: 'ShortText',
              textColor: 'eggshell',
              textTheme: 'text',
              paddingTop: 'fc-padding-top-0',
              elementName: '',
              isUpperCase: false,
              paddingLeft: 'fc-padding-left-0',
              paddingRight: 'fc-padding-right-0',
              paddingBottom: 'fc-padding-bottom-4',
              textAlignment: 'fc-text-left',
              _editable: '\u003C!--#storyblok#{"name": "ShortText", "space": "152150", "uid": "49c89cd9-c855-400c-9db7-880f1d582a8a", "id": "196275687"}--\u003E',
            },
            {
              _uid: 'ec673541-dbff-43e6-bcc4-4ab593fc36eb',
              codeId: 'ShopNow',
              ctaLink: {
                id: 'f671512d-e648-448c-a5a2-3cfbbaaec0ea',
                url: '',
                linktype: 'story',
                fieldtype: 'multilink',
                cached_url: 'shop',
              },
              ctaText: 'Shop Now',
              component: 'LinkButton',
              fullWidth: false,
              buttonTheme: 'button-secondary',
              buttonTextSize: 'default',
              buttonAlignment: 'left',
              mobileAlignmentCenter: false,
              _editable: '\u003C!--#storyblok#{"name": "LinkButton", "space": "152150", "uid": "ec673541-dbff-43e6-bcc4-4ab593fc36eb", "id": "196275687"}--\u003E',
            },
            {
              _uid: 'b2a44607-f129-4d6c-bc87-6469e67d5b9a',
              codeId: '',
              component: 'MediaBackground',
              isFullWidth: false,
              mediaPosition: '',
              mediaContainer: [
                {
                  _uid: 'eee67a0f-67f9-4914-b17b-4c3e8bddaced',
                  codeId: '',
                  mp4Video: {
                    id: 5019815,
                    alt: '',
                    name: '',
                    focus: '',
                    title: '',
                    filename: 'https://a.storyblok.com/f/152150/x/e53aa7d9b4/fc-atf-home-video-v3-002.mp4',
                    copyright: '',
                    fieldtype: 'asset',
                    is_external_url: false,
                  },
                  component: 'Video',
                  isOverlay: false,
                  webmVideo: {
                    id: 5019813,
                    alt: '',
                    name: '',
                    focus: '',
                    title: '',
                    filename: 'https://a.storyblok.com/f/152150/x/161de7301c/fc-atf-home-video-v3-002.webm',
                    copyright: '',
                    fieldtype: 'asset',
                    is_external_url: false,
                  },
                  mp4VideoUrl: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-oct-compressed-001.mp4',
                  webmVideoUrl: '',
                  firstFrameImage: {
                    id: 17103024,
                    alt: '',
                    name: '',
                    focus: '',
                    title: '',
                    source: '',
                    filename: 'https://a.storyblok.com/f/152150/2048x858/269aebbf3b/fc-antm-30-aug-first-frame-001.jpg',
                    copyright: '',
                    fieldtype: 'asset',
                    meta_data: {

                    },
                    is_external_url: false,
                  },
                  mp4MobileVideoUrl: '',
                  webmMobileVideoUrl: '',
                  mobileVideoPlayback: '',
                  tabletUpVideoPlayback: '',
                  _editable: '\u003C!--#storyblok#{"name": "Video", "space": "152150", "uid": "eee67a0f-67f9-4914-b17b-4c3e8bddaced", "id": "196275687"}--\u003E',
                },
              ],
              backgroundColor: '',
              _editable: '\u003C!--#storyblok#{"name": "MediaBackground", "space": "152150", "uid": "b2a44607-f129-4d6c-bc87-6469e67d5b9a", "id": "196275687"}--\u003E',
            },
          ],
          stackContentMobile: false,
          stackContentTabletUp: false,
          verticalContentAlignment: '36',
          horizontalContentAlignment: 'fc-align-start',
          verticalContentAlignmentBottom: '',
          _editable: '\u003C!--#storyblok#{"name": "Hero", "space": "152150", "uid": "0cb6afdc-ba94-4ae8-b6e6-41ad6da436cc", "id": "196275687"}--\u003E',
        },
      ],
      heroHeightPercentage: '100',
      _editable: '\u003C!--#storyblok#{"name": "AboveTheFoldView", "space": "152150", "uid": "97231b6d-307a-4126-9d8a-43fb08bc1a3c", "id": "196275687"}--\u003E',
    },
  ],
  elementId: 'FCWhyChooseATF',
  minHeight: '',
  inContainer: false,
  backgroundColor: '',
  customBackgroundColor: '',
  _editable: '\u003C!--#storyblok#{"name": "PageSection", "space": "152150", "uid": "e95b0403-0dc9-4ee2-ae74-5176c50fe28d", "id": "196275687"}--\u003E',
};

export default {
  name: 'WebsiteV3Home',
  components: {
    PageSection,
    Articles,
    TestimonialVideos,
    ReviewsGrid,
    LinkButton,
    MediaBackground,
    SplitView,
  },
  mounted() {
    if (window.affirm && window.affirm.ui) {
      window.affirm.ui.ready(() => {
        window.affirm.ui.refresh();
      });
    }
  },
  data() {
    return {
      breakDown: breakDownData,
      banner1,
      banner2,
      reviews: [],
      atfv3,
      logos: [
        'press-Men_s_Health.png',
        'press-esquire.png',
        'press-BI.png',
        'press-TIME-Logo.png',
        'press-People-Magazine.png',
        'press-wired.png',
        'press-forbes.png',
        'press-fitt-insider.png',
        'press-Today_logo.png',
      ],
      quotes: [
        '"The basic techniques for punches and kicks were taught in an accessible, responsible manner, which is more than I can say for some coaches I\'ve worked with IRL."',
        '"The workouts are fun but tough. Honestly, they’ve been one of the only things that actually gets me excited to work out."',
        '"The quality of what\'s included in each FightCamp package makes the entry fee well worth the initial investment. Everything from the gloves and wraps to the free-standing bag feel like premium products, and nothing you\'d have to replace anytime soon."',
        '"You\'ll get stats based on everything from your jab speed to intensity to types of punches thrown while you hone your skills."',
        '"I have focused myself on a workout regimen and meal plan that suits me and keeps me energized for workouts and I was lucky to find @fightcamp"',
        '"We tried this out at CES and our arms may never be the same."',
        '"Wrapping your hands and pulling on gloves is a ritualistic (and necessary) donning of armor. Each punch is harder than the last as you feel the release of whatever stress you\'re holding on to. The workout is almost superfluous. Or, it would be, if it wasn\'t so damn hard."',
        '"The new system, which connects four of the company’s proprietary trackers, will allow users to compete against and collaborate with friends and family members in real time, a nod to the in-home, two-player dynamic Nintendo introduced with its video game console back in the 1980s."',
        '"After almost a year of working out at home I had lost the motivation to really push myself during my workouts, and I had plateaued with my physical results. FightCamp helped me push through that wall."',
      ],
    };
  },
  methods: {
    updateTotal(price) {
      this.totalPrice = price;
    },
  },
  async fetch() {
    try {
      const storyBlok = new CMSService({ cmsService: this.$storyapi });
      const reviews = await storyBlok.getAll('user-reviews', { sort_by: 'content.reviewDate:desc' });

      this.reviews = reviews;
    } catch (e) {
      console.log(e);
    }
  },
};
