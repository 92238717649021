
import { defineComponent, inject, computed } from '@vue/composition-api';
import { IPackageProperties } from '~/types/fightcamp';
import ProductPackageCards from '~/components/storyblok/ProductPackageCards/ProductPackageCards.vue';

export default defineComponent({
  name: 'HomeBrowsePackagesVariant',
  components: {
    ProductPackageCards,
  },
  setup() {
    const packagesData = inject<IPackageProperties[]>('fcPackagesData', []); // Provide a default value of an empty array
    const getTitle = computed(() => 'Meet FightCamp');

    return {
      packagesData,
      getTitle,
    };
  },
});
