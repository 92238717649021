import { ref, watch } from '@vue/composition-api';

/**
 * The useAtfElement composable calculates the height of the ATF element
 * to take up the entire viewport height minus the height of the navigation bar.
 */

export function useAtfElement() {
  const atfElement = ref<HTMLElement | null>(null);

  const setAtfElementHeight = () => {
    if (atfElement.value && process.client) {
      const navBar = document?.getElementById('mainNavigation');

      if (navBar) {
        const windowHeight = (window?.innerHeight || document?.documentElement?.clientHeight)
          ? `${window?.innerHeight || document?.documentElement?.clientHeight}px`
          : '100vh';

        atfElement.value.style.height = `calc(${windowHeight} - ${navBar.offsetHeight}px)`;
      }
    }
  };

  watch(atfElement, setAtfElementHeight);

  return {
    atfElement,
  };
}
