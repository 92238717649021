
import { defineComponent, computed } from '@vue/composition-api';
import { LinkButton } from '@fc/angie-ui';
import MediaBackground from '~/components/storyblok/MediaBackground/MediaBackground.vue';
import DownloadAppIcons from '~/components/storyblok/DownloadAppIcons/DownloadAppIcons.vue';
import { useAtfElement } from '~/composables/useAtfElement';

export default defineComponent({
  name: 'ConsoleATF',
  components: {
    MediaBackground,
    LinkButton,
    DownloadAppIcons,
  },
  setup() {
    const shopLink = computed(() => 'shop/products/fightcamp-starter');
    const { atfElement } = useAtfElement();

    return {
      atfElement,
      headlineText: 'FightCamp is Boxing, Kickboxing & More',
      firstFrameImageUrl: 'https://a.storyblok.com/f/152150/2048x858/269aebbf3b/fc-antm-30-aug-first-frame-001.jpg',
      mp4MobileVideo: '',
      mp4Video: 'https://cdn.joinfightcamp.com/videos/fc-antm-30-oct-compressed-001.mp4',
      webmMobileVideo: '',
      webmVideo: '',
      appleBranchLink: {
        url: 'https://fightcamp.app.link/y3nZxmYIDob',
        linktype: 'url',
        fieldtype: 'multilink',
        cached_url: 'https://fightcamp.app.link/y3nZxmYIDob',
      },
      googleBranchLink: {
        id: '',
        url: 'https://fightcamp.app.link/76ZCQs5IDob',
        linktype: 'url',
      },
      iconDimensions: {
        apple: {
          mobileHeight: '36px',
          tabletUpHeight: '48px',
        },
        google: {
          mobileHeight: '48px',
          tabletUpHeight: '66px',
        },
      },
      shopLink,
    };
  },
});
